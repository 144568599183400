import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLogos } from "services/logoServices";

export const fetchLogos = createAsyncThunk("logos/fetchLogos", async () => {
  const response = await getAllLogos();
  if (response?.data?.success) {
    return response?.data?.data;
  }
  throw new Error("Failed to fetch logos");
});

const logoSlice = createSlice({
  name: "logos",
  initialState: {
    logos: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearLogos: (state) => {
      state.logos = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogos.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLogos.fulfilled, (state, action) => {
        state.loading = false;
        state.logos = action.payload;
        state.error = null;
      })
      .addCase(fetchLogos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearLogos } = logoSlice.actions;
export const selectLogos = (state) => state?.logo?.logo?.logos;
export const selectLogosLoading = (state) => state?.logo?.logo?.loading;
export const selectLogosError = (state) => state?.logo?.logo?.error;

export default logoSlice.reducer;
