import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  favicon: "",
};

export const faviconSlice = createSlice({
  name: "favicon",
  initialState,
  reducers: {
    setFavicon: (state, action) => {
      // console.log("state", state);
      state.favicon = action?.payload;
    },
  },
});

export const { setFavicon } = faviconSlice.actions;

export default faviconSlice.reducer;
