import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const FaviconUpdater = () => {
  const faviconUrl = useSelector((state) => state?.favicon?.favicon?.favicon);

  useEffect(() => {
    if (faviconUrl) {
      let link = document.querySelector("link[rel*='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = faviconUrl;

      let ogMeta = document.querySelector("meta[property='og:image']");
      if (!ogMeta) {
        ogMeta = document.createElement("meta");
        ogMeta.setAttribute("property", "og:image");
        document.head.appendChild(ogMeta);
      }
      ogMeta.setAttribute("content", faviconUrl);
    }
  }, [faviconUrl]);

  return null;
};

export default FaviconUpdater;
